<template>
  <!-- 头条新建广告抽屉内容2.0 -->
  <div class="toutiaLdeasSet paddbox">
    <div v-for="(i, indexs) in allArr" class="toucs">
      <el-card>
        <div class="toutiaLdeasSetitem">
          <el-form
            ref="ruleFormRef"
            :model="i"
            :rules="rules"
            label-width="120px"
          >
            <el-form-item label="标题组" prop="id">
              <!-- <el-select
                v-model="i.id"
                class="m-2"
                placeholder="请选择标题组"
                size="large"
              >
                <el-option
                  v-for="item in selectGroupTitleArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select> -->
              <div class="cursor ldTitle" @click="openNewWindows">
                新增标题
              </div>
              <!-- <div class="cursor ldTitle" @click="flushTitleFun">
                刷新标题
              </div> -->
              <div class="cursor ldTitle" @click="selectTitleFun(indexs)">
                添加视频
              </div>
            </el-form-item>
            <el-form-item>
              <showVideo
                :index="indexs"
                :area="area"
                :sucaiData="i.selectVideoData"
                @getsucaiDataChange="getsucaiDataChange"
              >
              </showVideo>
            </el-form-item>
            <el-form-item
              label="预算"
              prop="budget"
              v-if="
                deliveryData.isVersion != 1
                  ? budgetOptimizeSwitch != 'ON'
                  : false
              "
            >
              <el-input
                v-model="i.budget"
                style="width:240px"
                placeholder="广告预算"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="出价"
              prop="cpaBid"
              v-if="
                deliveryData.bidState
              "
            >
              <el-input v-model="i.cpaBid" style="width:240px"></el-input>
            </el-form-item>
            <el-form-item label="广告名称" prop="promotionName">
              <el-input
                v-model="i.promotionName"
                style="width:240px"
              ></el-input>
            </el-form-item>
            <el-form-item label="来源" prop="source">
              <el-input
                v-model="i.source"
                style="width:240px"
                placeholder="字数限制：2-10"
                show-word-limit
                minlength="2"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <div>
              <el-dialog v-model="i.wt" width="1600px">
                <selectToutiaoVideo
                  @getTitleId="getTitleId"
                  @getSelectSucaiVideo="getSelectSucaiVideo"
                  :waiChangeVideo="allArr[indexs].selectVideoData"
                  :deliveryData="deliveryData"
                ></selectToutiaoVideo>
              </el-dialog>
            </div>

            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="i.productName"
                placeholder="字数限制：1-20"
                show-word-limit
                maxlength="20"
                type="text"
                style="width:240px"
              ></el-input>
            </el-form-item>
            <!-- :on-success="onSuccess" -->
            <!-- action="http://dfrt.natapp4.cc/v2/Toutiaoplanv2/uploadProductImage" -->
            <!--   action="https://wxappvue.df01.top/api/v2/Toutiaoplanv2/uploadProductImage" -->
            <el-form-item label="产品主图">
              <el-upload
                class="avatar-uploader"
                :headers="{ authorize: token }"
                action="https://wxappvue.df01.top/api/v2/Toutiaoplanv2/uploadProductImage"
                :data="imageUrl"
                :on-success="
                  (event, file, fileList) =>
                    onSuccess(event, file, fileList, indexs)
                "
                multiple
                :show-file-list="false"
                :limit="50"
              >
                <el-button
                  size="small"
                  type="success"
                  round
                  style="maring-right:20px"
                  v-if="i.image_id.length < 10"
                  >上传图片
                </el-button>
                <span>
                  已选({{ i.image_id.length }}/10)张 图片大小必须<span
                    style="color:red"
                    >108*108</span
                  ></span
                >
              </el-upload>
              <div class="imglist" v-if="i.image_id.length > 0">
                <div class="imglistItem" v-for="(item, index) in i.image_id">
                  <img
                    :src="item.url"
                    alt=""
                    style="width: 100px;height: 100px;"
                  />
                  <div class="del_bg" @click="deleimg(index, indexs)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="落地页">
              <!-- <el-button @click="toutiaLdeasSetFun">添加</el-button> -->
              <el-table :data="i.SonAccountDataNew" border style="width: 100%">
                <el-table-column
                  prop="advertiserId"
                  label="账号"
                  width="180"
                ></el-table-column>
                <el-table-column label="落地页" prop="status" width="180">
                  <template #default="scope">
                    <el-select
                      v-model="scope.row.value"
                      placeholder="请选择"
                      @click="getV2orangeSiteFun(scope.$index, indexs)"
                    >
                      <el-option
                        v-for="item in scope.row.list"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="文案" prop="status">
                  <template #default="scope">
                    <el-input
                      placeholder="最多10个,每个必须5~55个字以内,用空格隔开"
                      @keyup.enter.native="batchWriterListFun(scope)"
                      v-model="scope.row.selling_points"
                    >
                      <template #append>
                        <span @click="batchWriterListFun(scope)">
                          批量添加（回车键）
                        </span>
                      </template>
                    </el-input>
                    <div
                      class="flex"
                      style="align-items: center;"
                      v-for="(item, index) in scope.row.writerList"
                    >
                      <el-input
                        v-model="item.val"
                        style="width:540px"
                        placeholder="字数限制：5-55"
                        show-word-limit
                        minlength="5"
                        maxlength="55"
                      ></el-input>
                      <div
                        class="menusItem-topItems"
                        @click="onAddChildrenmenus(scope)"
                        v-if="
                          index == scope.row.writerList.length - 1 &&
                            scope.row.writerList.length != 10
                        "
                      >
                        <i class="el-icon-plus"></i>
                      </div>
                      <div
                        class="menusItem-topItems"
                        @click="delewriter(index, scope)"
                        v-if="scope.row.writerList.length > 1"
                      >
                        <i class="el-icon-minus"></i>
                      </div>
                      <div
                        class="menusItem-topItems"
                        @click="clearWriter(index, scope)"
                        v-if="
                          index == scope.row.writerList.length - 1 &&
                            scope.row.writerList.length != 10
                        "><i class="el-icon-delete"></i></div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item>
              <!-- <div>
                <font color="red">* 注意:文案自动匹配每条计划，文案越多，重复度越低。</font>
              </div> -->
            </el-form-item>
            <el-form-item label="产品卖点">
              <el-input
                placeholder="最多10个,每个必须6~9个字以内,用空格隔开"
                @keyup.enter.native="ldTableEnterFun(indexs)"
                v-model="i.selling_points"
              >
                <template #append>
                  <span @click="ldTableEnterFun(indexs)">
                    添加（回车键）
                  </span>
                </template>
              </el-input>
              <div class="cybox" v-if="i.ldTablearr.length > 0">
                <div class="adListTitle el-row">
                  <div class="">已选({{ i.ldTablearr.length }}/10)</div>
                  <div class="">
                    <button
                      type="button"
                      class="el-button el-button--text el-button--small"
                      @click="ldTabAllDeleteFun(indexs)"
                    >
                      <span> 清除 </span>
                    </button>
                  </div>
                </div>
                <div class="flex es">
                  <div
                    class="adSy el-row"
                    v-for="(item, index) in i.ldTablearr"
                  >
                    <div class="">
                      <span>{{ item }}</span>
                    </div>
                    <div class="">
                      <button
                        type="button"
                        class="el-button el-button--text el-button--small"
                        @click="deleteLdTabFun(index, indexs)"
                      >
                        <span> × </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="行动号召">
              <el-input
                placeholder="最多10个,每个必须2~6个字以内,用空格隔开"
                @keyup.enter.native="ldTableEnterFun2(indexs)"
                v-model="i.callToActionButtons"
              >
                <template #append>添加（回车键）</template>
              </el-input>
              <div class="cybox" v-if="i.ldTablearr2.length > 0">
                <div class="adListTitle el-row">
                  <div class="">已选({{ i.ldTablearr2.length }}/10)</div>
                  <div class="">
                    <button
                      type="button"
                      class="el-button el-button--text el-button--small"
                      @click="ldTabAllDeleteFun2(indexs)"
                    >
                      <span> 清除 </span>
                    </button>
                  </div>
                </div>
                <div class="flex es">
                  <div
                    class="adSy el-row"
                    v-for="(item, index) in i.ldTablearr2"
                  >
                    <div class="">
                      <span>{{ item }}</span>
                    </div>
                    <div class="">
                      <button
                        type="button"
                        class="el-button el-button--text el-button--small"
                        @click="deleteLdTabFun2(index, indexs)"
                      >
                        <span> × </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="短剧类型">
              <el-radio-group v-model="i.isIaa" size="large">
                <el-radio-button :label="0">付费短剧</el-radio-button>
                <el-radio-button :label="1">iaa短剧</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <div style="margin-bottom:20px;margin-left:40px">
              <el-table :data="i.SonAccountDataNew" border style="width: 100%">
                <el-table-column
                  prop="advertiserId"
                  label="账号"
                  width="160"
                ></el-table-column>
                <!-- <el-table-column label="落地页" prop="status" width="180">
                  <template #default="scope">
                    <el-select
                      v-model="scope.row.value"
                      placeholder="请选择"
                      @click="getV2orangeSiteFun(scope.$index, indexs)"
                    >
                      <el-option
                        v-for="item in scope.row.list"
                        :label="item.name"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-table-column> -->
                <el-table-column label="授权" prop="status" width="200">
                  <template #default="scope">
                    <el-switch
                      v-model="scope.row.nativeSettings"
                      inactive-value="account"
                      active-value="douyin"
                      active-text="抖音号"
                      inactive-text="账户信息"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="抖音号" prop="status" width="180">
                  <template #default="scope">
                    <el-select
                      v-model="scope.row.awemeId"
                      placeholder="请选择"
                      @click="getAwemeAuthListFun(scope.$index, indexs)"
                      @change="getAwemeAuthListIDsbFun(scope.$index, indexs)"
                    >
                      <el-option
                        v-for="item in scope.row.getAwemeAuthList"
                        :key="item.awemeId"
                        :label="item.awemeName"
                        :value="item.awemeId"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="锚点信息" prop="status" width="300">
                  <template #default="scope">
                    <!-- <el-switch
                      v-model="scope.row.anchorRelatedType"
                      inactive-value="OFF"
                      active-value="SELECT"
                      active-text="手动选择"
                      inactive-text="不启用"
                    >
                    </el-switch> -->
                    <el-radio-group
                      v-model="scope.row.anchorRelatedType"
                      size="small"
                    >
                      <el-radio-button :label="'OFF'">不启用</el-radio-button>
                      <el-radio-button :label="'AUTO'"
                        >自动生成</el-radio-button
                      >
                      <el-radio-button :label="'SELECT'"
                        >手动选择</el-radio-button
                      >
                    </el-radio-group>
                  </template>
                </el-table-column>
                <el-table-column label="选择锚点" prop="status" width="180">
                  <template #default="scope">
                    <el-select
                      v-model="scope.row.anchorId"
                      placeholder="请选择"
                      @click="getNativeAnchorFun(scope.$index, indexs)"
                      @change="getNativeAnchorIDsbFun(scope.$index, indexs)"
                    >
                      <el-option
                        v-for="item in scope.row.getNativeAnchor"
                        :key="item.anchorId"
                        :label="item.anchorName"
                        :value="item.anchorId"
                      />
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="formbtn">
              <div>当前({{ allArr.length }}条)</div>
              <el-button
                v-if="indexs + 1 == allArr.length"
                type="success"
                @click="submitForm(ruleFormRef, indexs)"
                >新增一条</el-button
              >
              <el-button
                v-if="indexs > 0 || allArr.length > 1"
                type="danger"
                @click="deleAllArr(indexs)"
                >删除一条</el-button
              >
            </div>
          </el-form>
        </div>
        <el-button type="primary" @click="saveAllArrFun">保存</el-button>
        <el-drawer
          size="50%"
          v-model="toutiaLdeasSetOn"
          :direction="direction"
          title="添加橙子落地页"
        >
          <div class="paddbox">
            <div class="flex">
              <div class="cyzj-right">
                <el-card>
                  <el-table
                    :data="SonAccountDataNew"
                    border
                    style="width: 100%"
                  >
                    <el-table-column
                      prop="advertiserId"
                      label="账号"
                      width="180"
                    ></el-table-column>
                    <el-table-column label="落地页" prop="status">
                      <template #default="scope">
                        <el-select
                          v-model="scope.row.value"
                          placeholder="请选择"
                          @click="getV2orangeSiteFun(scope.$index, indexs)"
                        >
                          <el-option
                            v-for="item in scope.row.list"
                            :label="item.value"
                            :value="item.value"
                          />
                        </el-select>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-card>
              </div>
            </div>
            <div class="formbtn cyzjss">
              <el-button type="primary" @click="submitForm2(ruleFormRef)"
                >确认</el-button
              >
            </div>
          </div>
        </el-drawer>
      </el-card>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, inject, watch } from 'vue'
import { ElMessage } from 'element-plus'
import selectToutiaoVideo from '@/components/batch/selectToutiaoVideo.vue'
import {
  getV2orangeSite,
  getAwemeAuthList,
  getNativeAnchor,
} from '@/request/new/videoBatchManagement'
import wenan from './json'
import ShowVideo from '@/components/batch/showVideo.vue'
export default {
  name: 'toutiaLdeasSet2',
  components: { selectToutiaoVideo, ShowVideo },
  props: [
    'selectGroupTitleArr',
    'selectIndustryArr',
    'SonAccountData',
    'SonAccountModelData',
    'SonAccountDataNew',
    'campaignDtoDataSmartBidType',
    'budgetOptimizeSwitch',
    'deliveryData',
  ],
  setup(props, context) {
    const parent = { ...context }
    // const multipleTableRef = ref(null)

    const state = reactive({
      nativeSettings: 'douyin',
      wenan: wenan.wenan,
      // 选中的videoIndex
      videoIndex: '',
      allArr: [
        {
          promotionName: '',
          id: '',
          titles: [],
          productName: '',
          source: '',
          //0 付费短剧 1 iaa短剧
          isIaa: 0,
          budget: '',
          cpaBid: '',
          image_ids: [],
          image_id: [],
          selling_points: '',
          callToActionButtons: '',
          externalUrlMaterialList: '',
          writerList: [{ val: '' }],
          SonAccountDataNew: JSON.parse(
            JSON.stringify(props.SonAccountDataNew)
          ),
          ldTablearr: [],
          ldTablearr2: [],
          // 选中的素材数据
          selectVideoData: [],
          // 选中的素材循环出来的id
          vids: [],
          // 弹窗的值
          wt: false,
        },
      ],

      //
      writerList: [{ val: '' }],
      campaignDtoDataSmartBidType: props.campaignDtoDataSmartBidType,
      //项目传递的数据
      deliveryData: props.deliveryData,
      // 选中的子账户值
      SonAccountDataNew: props.SonAccountDataNew,
      product_selling_points: [],
      product_selling_pointsArr: [{ t: '' }],
      // 获取用户token
      token: 'Bearer' + localStorage.getItem('token'),
      userName: localStorage.getItem('userName'),
      image_ids: [],

      image_id: [],
      enable_personal_action: 0,
      dialogFormVisible: false,
      // cascaderProps: ,
      SonAccountData: props.SonAccountData,
      selectGroupTitleArr: props.selectGroupTitleArr,
      selectIndustryArr: props.selectIndustryArr,
      SonAccountModelData: props.SonAccountModelData,
      multipleTableRef: '',
      // 创意分类
      third_industry_id: '',
      tableData: [],
      // 表格选择的 创意组件id
      component_materials: [],
      toutiaLdeasSetOn: false,
      ruleFormRef: '',
      callToActionButtons: '',
      selling_points: '',
      ldTablearr: [],
      ldTablearr2: [],
      options: [],
      ruleForm: {
        source: '',
        promotionName: '',
        id: '',
        component_name: '',
        title: '',
        productName: [],
        budget: '',
      },
      // 必填规则
      rules: {
        promotionName: [
          { required: true, message: '请输入广告名称', trigger: 'blur' },
        ],
        source: [
          { required: true, message: '请输入来源', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在 2 到 10 个字符',
            trigger: 'blur',
          },
        ],
        budget: [{ required: true, message: '请输入预算', trigger: 'blur' }],
        cpaBid: [{ required: true, message: '请输入出价', trigger: 'blur' }],
        // id: [{ required: true, message: '请选择标题组', trigger: 'change' }],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
        ],
        title: [{ required: true, message: '请输入卡片标题', trigger: 'blur' }],
        component_name: [
          {
            required: true,
            message: '请输入组件名称',
            trigger: 'blur',
            max: 10,
          },
        ],
        button_text: [
          {
            required: true,
            message: '行动号召最少不能低于2个字',
            trigger: 'blur',
            min: 2,
          },
        ],
      },
      // =====================彬 ==================================
      // 选中的素材数据
      // selectVideoData: [],
      // // 选中的标题组id
      // selectTid: '',
      // 所在区域
      area: 'on',
      // 获取选中的标题组id
      getTitleId: (val) => {
        state.allArr[state.videoIndex].selectTid = val
        console.log('【外界】接收到了标题组id', val)
      },
      // 获取选中的数据组
      getSelectSucaiVideo: (val) => {
        state.allArr[state.videoIndex].selectVideoData = val
        console.log('【外界】接收到了', val)
        state.allArr[state.videoIndex].wt = false
      },
      // 获取【展示组件】选中数据组的改变
      getsucaiDataChange: (index, index2) => {
        console.log(
          '【外界】allArr标题组改变',
          index,
          '==外面组件索引=',
          index2
        )
        state.allArr[index2].selectVideoData.splice(index, 1)
      },

      // ========================彬  end================================

      // 通过优化目标获取橙子落地页站点信息
      getV2orangeSiteFun: (index, i) => {
        console.log(state.SonAccountDataNew[index], i)
        getV2orangeSite({
          advertiserId: state.SonAccountDataNew[index].advertiserId,
        }).then((res) => {
          state.allArr[i].SonAccountDataNew[index].list = res.data
        })
      },
      // 通过账户ID获取抖音号信息
      getAwemeAuthListFun: (index, i) => {
        getAwemeAuthList({
          accountId: state.SonAccountDataNew[index].advertiserId,
        }).then((res) => {
          state.allArr[i].SonAccountDataNew[index].getAwemeAuthList = res.data
        })
      },
      // 通过账户ID获取锚点
      getNativeAnchorFun: (index, i) => {
        getNativeAnchor({
          accountId: state.SonAccountDataNew[index].advertiserId,
        }).then((res) => {
          console.log(res)
          state.allArr[i].SonAccountDataNew[index].getNativeAnchor = res.data
        })
      },
      // 循环拿到选中的锚点ID,
      getNativeAnchorIDsbFun: (index, i) => {
        state.allArr[i].SonAccountDataNew[index].getNativeAnchor.forEach(
          (el) => {
            if (
              el.anchorId == state.allArr[i].SonAccountDataNew[index].anchorId
            ) {
              state.allArr[i].SonAccountDataNew[index].anchors = {
                anchor_type: el.anchor_type,
                anchorId: el.anchorId,
              }
            }
          }
        )
      },
      // 循环拿到选中的抖音号,
      getAwemeAuthListIDsbFun: (index, i) => {
        state.allArr[i].SonAccountDataNew[index].flag =
          state.allArr[i].SonAccountDataNew[index].nativeSettings
        state.allArr[i].SonAccountDataNew[index].awemeAuth = {
          awemeId: state.allArr[i].SonAccountDataNew[index].awemeId,
        }
      },
      // 新增文案
      onAddChildrenmenus: (scope) => {
        if (scope.row.writerList.length < 10) {
          scope.row.writerList.push({ val: '' })
        } else {
          ElMessage({
            type: 'error',
            message: '最多十条',
          })
        }
      },
      // 批量新增文案
      batchWriterListFun: (scope) => {
        if (
          scope.row.writerList.length +
            scope.row.selling_points.trim().split(/\s+/).length >
          10
        ) {
          ElMessage({
            type: 'error',
            message: '不能超过10个',
          })
          return
        }
        if (
          scope.row.selling_points != '' &&
          scope.row.selling_points.trim() != ''
        ) {
          // scope.row.writerList = scope.row.writerList.concat(
          //   scope.row.selling_points.trim().split(/\s+/)
          // )
          scope.row.writerList = scope.row.writerList.concat(
            scope.row.selling_points
              .trim()
              .split(/\s+/)
              .map((item) => ({ val: item }))
          )
          scope.row.writerList = scope.row.writerList.filter(
            (item) => !!item.val
          )
          scope.row.selling_points = ''
        }
        console.log(scope.row.writerList)
      },
      // 删除一条计划
      deleAllArr: (indexs) => {
        state.allArr.splice(indexs, 1)
      },
      // 删除图片
      deleimg: (index, indexs) => {
        state.allArr[indexs].image_ids.splice(index, 1)
        state.allArr[indexs].image_id.splice(index, 1)
      },
      // 删除文案
      delewriter: (index, scope) => {
        scope.row.writerList.splice(index, 1)
      },
      //清空选中的文案列表
      clearWriter:(index, scope)=>{
        scope.row.writerList = [{ val: '' }]
      },
      // 上传图片成功
      onSuccess: (response, z, x, indexs) => {
        console.log(response.data)
        // selectAllPictureFun()
        // state.imageUrl = response
        // 给接口的数据
        state.allArr[indexs].image_ids.push(response.data.imageId)
        // 显示用
        state.allArr[indexs].image_id.push(response.data)
      },
      // 打开落地页
      toutiaLdeasSetFun: () => {
        state.toutiaLdeasSetOn = true
      },
      // 新增一条
      submitForm: async (formEl, indexs) => {
        if (!formEl) {
          console.log(formEl)
          return
        }
        await formEl.validate((valid, fields) => {
          if (valid) {
            // if (state.ruleForm.budget < 300) {
            //   ElMessage({
            //     type: 'error',
            //     message: '预算需要大于等于300',
            //   })
            //   return
            // }
            const titles = []
            // 判断有没有视频

            if (state.allArr[indexs].selectVideoData.length == 0) {
              return ElMessage({
                type: 'error',
                message: '标题组不能为空',
              })
            }
            // 判断有没有文案

            // if (
            //   state.allArr[indexs].writerList.length < 1 &&
            //   state.allArr[indexs].writerList[0].val != ''
            // ) {
            //   ElMessage({
            //     type: 'error',
            //     message: '请填写文案',
            //   })
            //   return
            // } else {
            //   // state.allArr[indexs].writerList.forEach((el) => {
            //   //   titles.push(el.val)
            //   // })
            //   // state.allArr[indexs].titles = titles
            // }
            if (
              state.allArr[indexs].ldTablearr.length < 1 ||
              !state.allArr[indexs].ldTablearr.every((e) => {
                return e.length >= 6 && e.length <= 9
              })
            ) {
              ElMessage({
                type: 'error',
                message: '请填写产品卖点,并不能小于6大于9个字',
              })
              return
            }

            if (
              state.allArr[indexs].ldTablearr2.length < 1 ||
              !state.allArr[indexs].ldTablearr2.every((e) => {
                return e.length >= 2 && e.length <= 6
              })
            ) {
              ElMessage({
                type: 'error',
                message: '请填写行动号召,并不能小于2大于6个字',
              })
              return
            }
            // 判断有没有图片
            const image_ids = []

            if (state.allArr[indexs].image_ids.length < 1) {
              ElMessage({
                type: 'error',
                message: '请添加主图',
              })
              return
            } else {
              // state.allArr[indexs].image_id.forEach((el) => {
              //   image_ids.push(el.imageId)
              // })
              // state.allArr[indexs].image_ids = image_ids
            }
            state.allArr.push(JSON.parse(JSON.stringify(state.allArr[indexs])))
            state.allArr[indexs + 1].SonAccountDataNew.forEach((el) => {
              el.writerList = [{ val: '' }]
            })
            state.allArr[indexs + 1].image_ids = state.allArr[indexs].image_ids
            // 视频数据
            state.allArr[indexs + 1].selectVideoData = []

            state.allArr[indexs + 1].wt = false
            // 11111
            // if (state.userName == '黄浩') {
            //   let usedNumbers = [] // 已使用的随机数

            //   const result = []

            //   while (result.length < 4) {
            //     const randomNum = Math.floor(Math.random() * wenan.wenan.length)

            //     if (
            //       !usedNumbers.includes(randomNum) &&
            //       !result.includes(randomNum)
            //     ) {
            //       state.allArr[indexs + 1].writerList.push({
            //         val: wenan.wenan[randomNum],
            //       })
            //       result.push(randomNum)
            //     }
            //   }
            //   usedNumbers.push(...result) // 将已使用的随机数加入数组中
            //   if (usedNumbers.length >= wenan.wenan.length - 4) {
            //     // 数组中只剩下4个数时，清空已使用的数
            //     usedNumbers = []
            //   }

            //   // state.allArr[indexs + 1].writerList = [
            //   //   { val: wenan.wenan[(indexs + 1) * 4 + 1] },
            //   //   { val: wenan.wenan[(indexs + 1) * 4 + 2] },
            //   //   { val: wenan.wenan[(indexs + 1) * 4 + 3] },
            //   //   { val: wenan.wenan[(indexs + 1) * 4 + 4] },
            //   // ]
            // }
            console.log(state.allArr)
            // const creativeDto = {
            //   promotionName: state.ruleForm.promotionName,
            //   id: state.ruleForm.id,
            //   titles: titles,
            //   productName: state.ruleForm.productName,
            //   source: state.ruleForm.source,
            //   budget: state.ruleForm.budget,
            //   cpaBid: state.ruleForm.cpaBid,
            //   image_ids: image_ids,

            //   externalUrlMaterialList: state.SonAccountDataNew,
            // }

            // parent.emit('creativeDtoFun', creativeDto)
          }
        })
      },
      // 最终确定
      saveAllArrFun: () => {
        if (state.allArr[length].selectVideoData.length == 0) {
          return ElMessage({
            type: 'error',
            message: '标题组不能为空',
          })
        }
        if (
          state.allArr[length].ldTablearr.length < 1 ||
          !state.allArr[length].ldTablearr.every((e) => {
            return e.length >= 6 && e.length <= 9
          })
        ) {
          ElMessage({
            type: 'error',
            message: '请填写产品卖点,并不能小于6大于9个字',
          })
          return
        }

        if (
          state.allArr[length].ldTablearr2.length < 1 ||
          !state.allArr[length].ldTablearr2.every((e) => {
            return e.length >= 2 && e.length <= 6
          })
        ) {
          ElMessage({
            type: 'error',
            message: '请填写行动号召,并不能小于2大于6个字',
          })
          return
        }
        state.allArr.forEach((el) => {
          el.selling_points = el.ldTablearr
          el.callToActionButtons = el.ldTablearr2
          el.externalUrlMaterialList = el.SonAccountDataNew
          el.anchorRelateds = el.SonAccountDataNew
          el.nativeSettings = el.SonAccountDataNew
          el.SonAccountDataNew.forEach((els) => {
            els.texts = []
            els.writerList.forEach((elss) => {
              els.texts.push(elss.val)
            })
          })
          el.titiesList = el.SonAccountDataNew
          // el.writerList.forEach((els) => {
          //   el.titles.push(els.val)
          // })
          // 提取选中素材的id
          el.vids = []
          el.selectVideoData.forEach((els) => {
            el.vids.push(els.id)
          })
        })
        console.log('保存：', state.allArr)
        parent.emit('creativeDtoFun', state.allArr)
      },
      // 小确定
      submitForm2: () => {
        state.toutiaLdeasSetOn = false
      },
      // 监听回车 新增创意标签
      ldTableEnterFun: (indexs) => {
        if (
          state.allArr[indexs].ldTablearr.length +
            state.allArr[indexs].selling_points.trim().split(/\s+/).length >
          10
        ) {
          ElMessage({
            type: 'error',
            message: '不能超过10个',
          })
          return
        }
        if (
          state.allArr[indexs].selling_points != '' &&
          state.allArr[indexs].selling_points.trim() != ''
        ) {
          state.allArr[indexs].ldTablearr = state.allArr[
            indexs
          ].ldTablearr.concat(
            state.allArr[indexs].selling_points.trim().split(/\s+/)
          )
          state.allArr[indexs].selling_points = ''
        }
      },
      // 删除创意标签
      deleteLdTabFun: (index, indexs) => {
        state.allArr[indexs].ldTablearr.splice(index, 1)
      },
      // 清除全部创意标签
      ldTabAllDeleteFun: (indexs) => {
        state.allArr[indexs].ldTablearr = []
      },
      // 监听回车 新增创意标签
      ldTableEnterFun2: (indexs) => {
        console.log(
          'state.allArr[indexs].callToActionButtons',
          state.allArr[indexs].callToActionButtons.trim().split(/\s+/)
        )
        if (
          state.allArr[indexs].ldTablearr2.length +
            state.allArr[indexs].callToActionButtons.trim().split(/\s+/)
              .length >
          10
        ) {
          ElMessage({
            type: 'error',
            message: '不能超过10个',
          })
          return
        }

        if (
          state.allArr[indexs].callToActionButtons != '' &&
          state.allArr[indexs].callToActionButtons.trim() != ''
        ) {
          state.allArr[indexs].ldTablearr2 = state.allArr[
            indexs
          ].ldTablearr2.concat(
            state.allArr[indexs].callToActionButtons.trim().split(/\s+/)
          )
          state.allArr[indexs].callToActionButtons = ''
        }
      },
      // 删除创意标签
      deleteLdTabFun2: (index, indexs) => {
        state.allArr[indexs].ldTablearr2.splice(index, 1)
      },
      // 清除全部创意标签
      ldTabAllDeleteFun2: (indexs) => {
        state.allArr[indexs].ldTablearr2 = []
      },

      // 刷新标题组
      flushTitleFun: () => {
        parent.emit('flushTitleFun', 'ok')
      },
      // 添加视频弹窗控制
      addVideoView: false,
      // 添加视频
      selectTitleFun: (index) => {
        // 选中视频所在数组index
        state.videoIndex = index
        console.log(
          '【外：添加视频==》】',
          state.videoIndex,
          '数据',
          state.allArr[index].selectVideoData
        )
        // console.log('改变前:', state.addVideoView)
        // state.addVideoView = !state.addVideoView
        state.allArr[index].wt = !state.allArr[index].wt
      },

      // 点击子账户请求创意组件数据
      checkedSonAccount: (val) => {
        parent.emit('getSonAccountFun', val)
      },
      // 新增标题(打开新页面)
      openNewWindows: () => {
        window.open('titleGroup') // 打开标题组页面
      },
    })

    // 个人用
    if (state.userName != 'css') {
      state.allArr[0].productName = '点击观看全集'
      state.allArr[0].ldTablearr = ['熬夜都要看的短剧']
      state.allArr[0].callToActionButtons = ['下一集']
      state.allArr[0].selling_points = ['熬夜都要看的短剧']
      state.allArr[0].ldTablearr2 = ['下一集']
      state.allArr[0].image_ids = [
        'ad-tetris-site/file/1679038698402/5986e21e08f7bc48a921ea12930d7e6b',
      ]
      state.allArr[0].image_id = [
        {
          imageId:
            'ad-tetris-site/file/1679038698402/5986e21e08f7bc48a921ea12930d7e6b',
          url:
            'https://oss.df01.top/toutiaoVideo/46732edc-fbf1-472f-ace3-06aaed60ec5f.jpg',
        },
      ]
      // if (state.userName == '黄浩') {
      //   state.allArr[0].writerList = [
      //     { val: wenan.wenan[0] },
      //     { val: wenan.wenan[1] },
      //     { val: wenan.wenan[2] },
      //     { val: wenan.wenan[3] },
      //   ]
      // }
      // state.allArr[0].writerList = [
      //   { val: wenan.wenan[0] },
      //   { val: wenan.wenan[1] },
      //   { val: wenan.wenan[2] },
      //   { val: wenan.wenan[3] },
      // ]
      // 删除、之前的字符
      // const newAr = wenan.wenan.map((str) => str.replace(/^\d+、/, ''))

      // 查重
      // function removeDuplicates(arr) {
      //   const result = []
      //   const obj = {}

      //   for (let i = 0; i < arr.length; i++) {
      //     obj[arr[i]] = true
      //   }
      //   for (const key in obj) {
      //     result.push(key)
      //   }
      //   return result
      // }
      // const uniqueArr = removeDuplicates(wenan.wenan)
      // console.log(uniqueArr)
    }
    return {
      ...toRefs(state),
    }
  },
}
</script>
<style lang="scss" scoped>
.toutiaLdeasSet {
  margin-top: 0;
}
.toucs {
  margin-bottom: 20px;
}
.menusItem-topItems {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
}
.del_bg {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  text-align: center;
  padding-top: 30px;
  font-size: 18px;
  transition: 1s;
  display: none;
}
.imglistItem {
  margin-top: 20px;
  display: flex;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;
}
.imglistItem:hover {
  .del_bg {
    display: block;
  }
}
.imglist {
  display: flex;
  width: 700px;
  flex-wrap: wrap;
}
.imageUrl {
  width: 120px;
  height: 120px;
}
.formbtn {
  text-align: right;
  margin-right: 50px;
}
.cyzjss {
  margin-top: 40px;
}
.cybox {
  display: inline-block;
  width: 520px;
  height: 200px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  overflow: auto;
  margin-top: 10px;
}
.adListTitle {
  justify-content: space-between;
  height: 40px;
  background-color: rgb(226, 224, 224);
  padding-left: 20px;
  line-height: 40px;
  padding-right: 20px;
  box-sizing: border-box;
}
.adSy {
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 220px;
  height: 30px;
  background-color: rgb(238, 231, 231);
  margin: 14px;
  border-radius: 5px;
  line-height: 30px;
}
.es {
  flex-wrap: wrap;
}
.cyzj-left {
  width: 19%;
  height: 600px;
  overflow: auto;
  margin-right: 20px;
  background-color: #fff;
  box-shadow: 0 6px 20px #f1f4f9;
  border-radius: 10px;
  flex-direction: column;
}
.cyzj-right {
  flex: 1;
}
.list_item {
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
}
.ldTitle {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  color: #409eff;
}
:deep(.el-table .disabledCheck .cell .el-checkbox__inner) {
  display: none;
}
</style>
