<template>
  <!-- 头条项目配置抽屉内容 -->
  <div class="toutiaoAdsSetDrawer paddbox">
    <el-card>
      <div class="toutiaoAdsSetDraweritem">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
        >
          <el-form-item label="模版名称" prop="modelName">
            <el-input v-model="ruleForm.modelName"></el-input>
          </el-form-item>
          <el-form-item label="项目名称" prop="projectName">
            <el-input v-model="ruleForm.projectName"></el-input>
          </el-form-item>
          <el-form-item label="项目状态" prop="projectName">
            <el-radio-group v-model="operation" size="large">
              <el-radio-button label="DISABLE">关闭</el-radio-button>
              <el-radio-button label="ENABLE">开启</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联产品" prop="projectName">
            <el-radio-group v-model="productSetting" size="large">
              <el-radio-button label="NO_MAP">不启用</el-radio-button>
              <el-radio-button label="SINGLE">启用</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" prop="campaign_name">
            <el-table
              :data="SonAccountDataNew3"
              border
              style="width: 100%"
              v-if="productSetting == 'SINGLE'"
            >
              <el-table-column
                prop="advertiserId"
                label="账号"
                width="180"
              ></el-table-column>
              <el-table-column label="商品库" prop="status">
                <template #default="scope">
                  <el-form-item label="商品库">
                    <el-select
                      v-model="scope.row.platformId"
                      placeholder="请选择"
                      filterable
                      @click="getProductAvailablesFun(scope)"
                    >
                      <el-option
                        v-for="item in scope.row.getProductAvailablesData"
                        :key="item.platformId"
                        :label="item.name"
                        :value="item.platformId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="产品" prop="status">
                <template #default="scope">
                  <el-form-item label="产品" v-if="scope.row.platformId">
                    <el-select
                      v-model="scope.row.productId"
                      placeholder="请选择"
                      filterable
                      @click="platformIdFun(scope)"
                    >
                      <el-option
                        v-for="item in scope.row.getProductDetailData"
                        :key="item.productId"
                        :label="item.name"
                        :value="item.productId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="项目类型">
            <el-radio-group
              v-model="isIaaProject"
              size="large"
            >
              <el-radio-button :label="0">短剧项目</el-radio-button>
              <el-radio-button :label="1">IAA项目</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <div>
              <font color="red">* 新版才有自动投放</font>
            </div>
          </el-form-item>
          <el-form-item label="版本类型">
            <el-radio-group
              v-model="isVersion"
              size="large"
              @change="deliveryModeFun()"
            >
              <el-radio-button :label="0">旧版</el-radio-button>
              <el-radio-button :label="1">新版</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="投放模式">
            <el-radio-group v-model="deliveryMode" size="large">
              <el-radio-button :label="'MANUAL'" :disabled="isVersion === 1"
                >手动投放</el-radio-button
              >
              <el-radio-button :label="'PROCEDURAL'" :disabled="isVersion === 0"
                >自动投放</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="推广目标">
            <el-radio-group v-model="landing_type" size="large" >
              <el-radio-button label="LINK">销售线索收集</el-radio-button>
              <el-radio-button label="MICRO_GAME">小程序</el-radio-button>
              <!-- <el-radio-button label="统一日预算" disabled>搜索广告</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="投放内容" v-if="landing_type == 'MICRO_GAME'">
            <el-radio-group v-model="microPromotionType" size="large">
              <el-radio-button label="WECHAT_APP">微信小程序</el-radio-button>
              <el-radio-button label="BYTE_APP">字节小程序</el-radio-button>
              <!-- <el-radio-button label="统一日预算" disabled>搜索广告</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="">
            <el-table
              :data="SonAccountDataNew4"
              border
              style="width: 100%"
              v-if="
                landing_type == 'MICRO_GAME' && microPromotionType == 'BYTE_APP'
              "
            >
              <el-table-column
                prop="advertiserId"
                label="账号"
                width="180"
              ></el-table-column>
              <el-table-column label="字节小程序">
                <template #default="scope">
                  <el-form-item>
                    <el-select
                      v-model="scope.row.instanceId"
                      placeholder="请选择字节小程序"
                      filterable
                      @click="getgetV2MicroAppFun(scope)"
                    >
                      <el-option
                        @click="getMicroAppLinkFun(scope)"
                        v-for="item in scope.row.getV2MicroAppData"
                        :key="item.instanceId"
                        :label="item.name"
                        :value="item.instanceId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="字节小程序链接">
                <template #default="scope">
                  <el-form-item>
                    <el-select
                      v-model="scope.row.startParam"
                      placeholder="请选择链接"
                      filterable
                    >
                      <el-option
                        @click="getgetV2MicroAppFun2(scope)"
                        v-for="item in scope.row.list"
                        :key="item.startParam"
                        :label="item.linkRemark"
                        :value="item.startParam"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="事件资产">
                <template #default="scope">
                  <el-form-item label="事件资产">
                    <el-select
                      v-model="scope.row.assetId"
                      placeholder="请选择"
                      filterable
                      @click="getgetV2AssetsFun(scope)"
                    >
                      <el-option
                        v-for="item in scope.row.assetIdData"
                        :key="item.assetId"
                        :label="item.assetName"
                        :value="item.assetId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="">
            <el-table
              :data="SonAccountDataNew5"
              border
              style="width: 100%"
              v-if="
                landing_type == 'MICRO_GAME' && microPromotionType == 'WECHAT_APP'
              "
            >
              <el-table-column
                prop="advertiserId"
                label="账号"
                width="180"
              ></el-table-column>
              <el-table-column label="微信小程序">
                <template #default="scope">
                  <el-form-item>
                    <el-select
                      v-model="scope.row.instanceId"
                      placeholder="请选择微信小程序"
                      filterable
                      @click="getWechatAppletListFun(scope)"
                    >
                      <el-option
                        v-for="item in scope.row.getWechatAppletListData"
                        :key="item.instanceId"
                        :label="item.name"
                        :value="item.instanceId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <!-- <el-table-column label="微信小程序链接">
                <template #default="scope">
                  <el-form-item>
                    <el-select
                      v-model="scope.row.startParam"
                      placeholder="请选择链接"
                      filterable
                    >
                      <el-option
                        @click="getgetV2MicroAppFun2(scope)"
                        v-for="item in scope.row.list"
                        :key="item.startParam"
                        :label="item.linkRemark"
                        :value="item.startParam"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="事件资产">
                <template #default="scope">
                  <el-form-item label="事件资产">
                    <el-select
                      v-model="scope.row.assetId"
                      placeholder="请选择"
                      filterable
                      @click="getgetV2AssetsFun(scope)"
                    >
                      <el-option
                        v-for="item in scope.row.assetIdData"
                        :key="item.assetId"
                        :label="item.assetName"
                        :value="item.assetId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column> -->
            </el-table>
          </el-form-item>
          <el-form-item label="营销场景">
            <el-radio-group v-model="marketingGoal" size="large">
              <el-radio-button label="VIDEO_AND_IMAGE"
                >短视频 + 图文</el-radio-button
              >
              <!-- <el-radio-button label="统一日预算" disabled>搜索广告</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告组类型">
            <el-radio-group v-model="adType" size="large">
              <el-radio-button label="ALL">所有广告</el-radio-button>
              <el-radio-button label="SEARCH" disabled
                >搜索广告</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优化目标">
            <el-radio-group v-model="externalAction" size="large">
              <el-select v-model="getV2optimizedGoal" placeholder="请选择">
                <el-option
                  v-for="item in getV2optimizedGoalData"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="深度优化目标"
            v-if="
              landing_type == 'MICRO_GAME' 
            "
          >
            <div class="flex">
              <div>
                <el-select
                  v-model="deepExternalAction"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in getV2ShenDuoptimizedGoalData"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div><font color="red"> * 不跑付费ROI可以不填</font></div>
            </div>
          </el-form-item>
          <el-form-item label="ROI系数" v-if="deepExternalAction != ''">
            <el-col :span="8">
              <el-input type="number" v-model="roiGoal" placeholder="ROI系数">
              </el-input>
            </el-col>
          </el-form-item>

          <el-form-item label="投放位置">
            <el-radio-group v-model="inventoryCatalog" size="large">
              <el-radio-button  :label="'UNIVERSAL_SMART'" 
                >通投智选</el-radio-button
              >
              <el-radio-button :label="'MANUAL'">首选媒体</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- 选了媒体后显示 -->
          <div class="firstMedia" v-if="inventoryCatalog == 'MANUAL'">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                :label="item.value"
                style="margin-bottom:10px"
                v-for="(item, index) in toutiaoMedia"
                >{{ item.text }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <el-form-item label="定向选择">
            <el-radio-group v-model="radio7" size="large">
              <el-radio-button :label="0">迪弗定向包</el-radio-button>
              <el-radio-button label="统一日预算" disabled
                >媒体定向包</el-radio-button
              >
            </el-radio-group>
            <div>
              <span
                class="cursor"
                style="color:#0062d9"
                @click="updateOptionsDataFun()"
                ><i class="el-icon-refres"></i>刷新定向包列表
              </span>
              <!-- <el-transfer
                style="margin-top:10px"
                :titles="['可选定向数量', '已选定向数量']"
                v-model="value"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入搜索内容"
                :data="data"
              /> -->
              <div>
                <el-select
                  filterable
                  v-model="dPackageId"
                  placeholder="请选择定向包"
                >
                  <el-option
                    @click="getLable(item)"
                    v-for="item in optionsData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <span class="cursor" style="color:#0062d9" @click="openTargetFun()"
              ><i class="el-icon-refres"></i>新增定向包
            </span>
          </el-form-item>
          <el-form-item label="投放时间">
            <el-radio-group v-model="scheduleType" size="large">
              <el-radio-button :label="'SCHEDULE_FROM_NOW'"
                >从今天起长期投放</el-radio-button
              >
              <el-radio-button :label="'SCHEDULE_START_END'"
                >设置开始和结束日期</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <!-- 不是从今天起长期投放的时候显示 -->
          <div class="firstMedia" v-if="scheduleType != 'SCHEDULE_FROM_NOW'">
            <el-date-picker
              v-model="value1"
              :default-value="new Date()"
              type="datetimerange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :size="size"
            />
          </div>

          <el-form-item label="投放时段">
            <el-radio-group v-model="launchTime" size="large">
              <el-radio-button :label="0">不限</el-radio-button>
              <el-radio-button :label="1">指定时间段</el-radio-button>
              <div v-if="launchTime == 1">
                <weektime
                  v-model="mult_timeRange"
                  :data="weektimeData"
                  @on-clear="clearWeektime"
                ></weektime>
              </div>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="竞价策略">
            <el-radio-group v-model="smartBidType" size="large">
              <el-radio-button :label="'CUSTOM'">
                <span style="font-size:16px">稳定成本</span>
                <div class="r_desc">
                  控制成本，尽量消耗完预算
                </div>
              </el-radio-button>
              <el-radio-button :label="'UPPER_CONTROL'">
                <span style="font-size:16px">最优成本</span>
                <div class="r_desc">
                  均匀消耗预算，成本不超过出价
                </div></el-radio-button
              >
              <el-radio-button :label="'NO_BID'">
                <span style="font-size:16px">最大转化</span>
                <div class="r_desc">
                  匀速花完预算，获取更多转化
                </div></el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <div>
              <font color="red"
                >*
                注意：销售线索的自动投放只有"稳定成本"!!!；小程序的自动投放只有"稳定成本"和"最大转化"!!!</font
              >
            </div>
          </el-form-item>
          <el-form-item
            label="预算择优分配"
            v-if="smartBidType == 'NO_BID' && isVersion == 0"
          >
            <el-radio-group v-model="budgetOptimizeSwitch" size="large">
              <el-radio-button label="OFF">不启用</el-radio-button>
              <el-radio-button label="ON">启用</el-radio-button>
              <!-- <el-input
                type="number"
                v-if="budgetOptimizeSwitch == 'ON'"
                v-model="budget"
                placeholder="请大于300"
              >
                <template #append>元/天</template>
              </el-input> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告组预算" v-if="isVersion == 0">
            <el-radio-group v-model="budgetMode" size="large">
              <el-radio-button label="BUDGET_MODE_INFINITE"
                >不限</el-radio-button
              >
              <el-radio-button label="BUDGET_MODE_DAY"
                >统一日预算</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="预算"
            v-if="
              budgetMode == 'BUDGET_MODE_DAY' ||
                deliveryMode == 'PROCEDURAL' ||
                isVersion == 1
            "
          >
            <el-col :span="8">
              <el-input type="number" v-model="budget" placeholder="请大于300">
                <template #append>元/天</template>
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item
            label="出价"
            v-if="isVersion == 1 ? (smartBidType == 'CUSTOM'?deepExternalAction!='AD_CONVERT_TYPE_LT_ROI':false) : false"
          >
            <el-col :span="8">
              <el-input
                type="number"
                v-model="cpaBid"
                placeholder="请出价"
                clearable
              >
                <template #append>元</template>
              </el-input>
            </el-col>
          </el-form-item>

          <el-form-item>
            <div>
              <font color="red"
                >* 选择小程序时，监测链接不是必填项（看平台方要求）</font
              >
            </div>
          </el-form-item>
          <el-form-item label="第三方监测链接" prop="campaign_name">
            <el-table :data="SonAccountDataNew" border style="width: 100%">
              <el-table-column
                prop="advertiserId"
                label="账号"
                width="180"
              ></el-table-column>
              <el-table-column label="点击监测链接" prop="status">
                <template #default="scope">
                  <el-input v-model="scope.row.value"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <div class="formbtn">
            <el-button type="primary" @click="submitForm(ruleFormRef)"
              >确认</el-button
            >
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import weektimeData from '@/components/weektime/weektime_data.js'
import weektime from '@/components/weektime/weektime.vue'
import { ElMessage } from 'element-plus'
import {
  getProductDetail,
  getV2ShenDuoptimizedGoal,
  getProductAvailables,
  getV2MicroApp,
  getWechatAppletList,
  getV2Assets,
} from '@/request/new/videoBatchManagement'
import { stringify } from 'qs'
export default {
  name: 'toutiaoprojectSetting2.vue',
  components: { weektime },
  props: [
    'data',
    'optionsData',
    'getV2optimizedGoalData',
    'SonAccountDataNew',
    'SonAccountDataNew3',
    'SonAccountDataNew4',
    'SonAccountDataNew5',
    'toutiaoMedia',
    'getProductAvailablesData',
  ],
  setup(props, context) {
    const parent = { ...context }
    const mult_timeRange = computed(() => {
      return weektimeData.map((item) => {
        return {
          id: item.row,
          week: item.value,
          value: splicing(item.child),
        }
      })
    })

    const splicing = (list) => {
      let same
      let i = -1
      const len = list.length
      const arr = []

      if (!len) {
        return
      }
      while (++i < len) {
        const item = list[i]

        if (item.check) {
          if (item.check !== Boolean(same)) {
            arr.push(...['、', item.begin, '~', item.end])
          } else if (arr.length) {
            arr.pop()
            arr.push(item.end)
          }
        }
        same = Boolean(item.check)
      }
      arr.shift()
      console.log(arr.shift())

      return arr.join('')
    }

    const state = reactive({
      //创意的出价是否要开启
      bidState:true,
      //选中的字节小程序
      microAppDtos: [],
      //选中的微信小程序
      wechatAppDtos:[],
      getV2ShenDuoptimizedGoalData: [],
      productSetting: 'SINGLE',
      //计划状态 ENABLE开启 DISABLE关闭
      operation: 'ENABLE',
      productId: '',
      platformId: '',
      budgetOptimizeSwitch: 'OFF',
      // 接收首选媒体
      toutiaoMedia: props.toutiaoMedia,
      // 首选媒体选中的值
      checkList: [
        'INVENTORY_FEED',
        'INVENTORY_VIDEO_FEED',
        'INVENTORY_AWEME_FEED',
      ],
      externalAction: '',
      getProductAvailablesData: props.getProductAvailablesData,
      // 选中的子账户值
      SonAccountDataNew: props.SonAccountDataNew,
      SonAccountDataNew3: props.SonAccountDataNew3,
      //字节小程序数据
      SonAccountDataNew4: props.SonAccountDataNew4,
      //微信小程序数据
      SonAccountDataNew5: props.SonAccountDataNew5,      
      // 选中的优化目标
      getV2optimizedGoal: 'AD_CONVERT_TYPE_PAY',
      microPromotionType: 'WECHAT_APP',
      budgetMode: 'BUDGET_MODE_INFINITE',
      // 竞价策略
      smartBidType: 'CUSTOM', // 常规投放 CUSTOM    最大转化投放 NO_BID
      // 投放时段 0不限 1指定时间端
      launchTime: 0,
      // 自定义投放时间段数据
      weektimeData: weektimeData,
      getProductDetailData: [],

      // 请求商品库
      getProductAvailablesFun: (e) => {
        getProductAvailables({ advertiserId: e.row.advertiserId }).then(
          (res) => {
            e.row.getProductAvailablesData = res.data
            e.row.productId = ''
          }
        )
      },

      // 选中商品库 请求商品
      platformIdFun: (e) => {
        console.log(e)
        getProductDetail({
          advertiserId: e.row.advertiserId,
          platformId: e.row.platformId,
        }).then((res) => {
          e.row.getProductDetailData = res.data
        })
      },
      // 请求字节小程序
      getgetV2MicroAppFun: (e) => {
        const datas = {
          accountId: e.row.advertiserId,
        }
        getV2MicroApp(datas).then((res) => {
          e.row.getV2MicroAppData = res.data
        })
      },
      //选择字节小程序
      getMicroAppLinkFun: (e) => {
        state.SonAccountDataNew4[e.$index].getV2MicroAppData.forEach((d) => {
          if (d.instanceId == state.SonAccountDataNew4[e.$index].instanceId) {
            state.SonAccountDataNew4[e.$index].list = d.list
          }
        })
      },
      //选择字节小程序链接
      getgetV2MicroAppFun2: (e) => {},

      // 请求事件资产
      getgetV2AssetsFun: (e) => {
        const datas = {
          accountId: e.row.advertiserId,
        }
        getV2Assets(datas).then((res) => {
          e.row.assetIdData = res.data
        })
      },

      // 请求微信小程序
      getWechatAppletListFun: (e) => {
        const datas = {
          accountId: e.row.advertiserId,
        }
        getWechatAppletList(datas).then((res) => {
          e.row.getWechatAppletListData = res.data
        })
      },      
      // 清除投放时段的值
      clearWeektime: () => {
        state.weektimeData.forEach((item) => {
          item.child.forEach((t) => {
            t.check = false
          })
        })
      },
      value1: '',
      // 0旧版 没有自动投放   1新版
      isVersion: 0,
      //项目类型  0短剧项目 1IAA项目
      isIaaProject:0,
      //投放模式  MANUAL手动投放(默认值）、PROCEDURAL自动投放
      deliveryMode: 'MANUAL',
      //roi系数
      roiGoal: 1.1,
      // 出价
      cpaBid: '',
      // 投放时间
      scheduleType: 'SCHEDULE_FROM_NOW', // SCHEDULE_FROM_NOW从今天起长期投放, SCHEDULE_START_END设置开始和结束日期
      inventoryCatalog: 'MANUAL',
      landing_type: 'LINK',

      deepExternalAction: '',
      marketingGoal: 'VIDEO_AND_IMAGE',
      adType: 'ALL',
      budget_mode: '0',
      budget: 300,
      // operation: 'enable',
      ruleFormRef: '',
      ruleForm: {
        projectName: '',
        modelName: '',
      },
      // 定向包单选值  0迪弗定向包
      radio7: 0,
      // 接收定向包数据
      optionsData: props.optionsData,
      // 接受转化目标
      getV2optimizedGoalData: props.getV2optimizedGoalData,
      // 选中的定向包
      dPackageId: '',
      // 选中的定向包名称
      dPackagename: '',
      // 新增定向包
      openTargetFun: () => {
        window.open('Directional')
      },
      // 刷新定向包
      updateOptionsDataFun: () => {
        parent.emit('updateOptions')
      },
      // 获取选中的定向包名称
      getLable: (val) => {
        state.dPackagename = val.name
      },
      //根据版本类型
      deliveryModeFun: () => {
        if (state.isVersion == 0) {
          state.deliveryMode = 'MANUAL'
        } else {
          state.deliveryMode = 'PROCEDURAL'
          state.budgetMode = 'BUDGET_MODE_DAY'
          // state.landing_type='LINK'
        }
      },
      // 必填规则
      rules: {
        modelName: [
          { required: true, message: '请输入模版名称', trigger: 'blur' },
        ],
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
      },
      // 确定
      submitForm: async (formEl) => {
        if (state.smartBidType == 'NO_BID') {
          ElMessage({
            type: 'warning',
            message: '当前竞价策略为《最大转化》',
          })
        }
        // 选中的定向包 state.dPackageId
        // 选中的定向包名称 state.dPackagename
        if (!formEl) {
          console.log(formEl)
          return
        }
        // campaignDto 广告组对象
        await formEl.validate((valid, fields) => {
          if (valid) {
            // 常规判断
            if (
              ((state.smartBidType == 'CUSTOM' &&
                state.budgetMode == 'BUDGET_MODE_DAY') ||
                state.isVersion == 1) &&
              state.budget < 300
            ) {
              ElMessage({
                type: 'error',
                message: '请填写大于300的预算',
              })
              return
            }
            // 普通最大化判断
            if (
              ((state.smartBidType == 'NO_BID' &&
                state.budgetMode == 'BUDGET_MODE_DAY' &&
                state.budgetOptimizeSwitch == 'OFF') ||
                state.isVersion == 1) &&
              state.budget < 100
            ) {
              ElMessage({
                type: 'error',
                message: '最大转化预算必须大于100',
              })
              return
            }
            // 择优分配最大化
            if (
              state.smartBidType == 'NO_BID' &&
              state.budgetOptimizeSwitch == 'ON' &&
              state.budget < 300
            ) {
              ElMessage({
                type: 'error',
                message: '预算择优分配必须大于300',
              })
              return
            }
            // 判断产品库
            if (state.productSetting == 'SINGLE') {
              if (
                !state.SonAccountDataNew3.every((e) => {
                  return e.platformId != '' && e.productId != ''
                })
              ) {
                ElMessage({
                  type: 'error',
                  message: '请选择完产品信息',
                })
                return
              }
            }
            // 判断微信小程序
            if(
              state.landing_type == 'MICRO_GAME' &&
              state.microPromotionType == 'WECHAT_APP'             
            ){
              if(!state.SonAccountDataNew5.every((e)=>{
                return (
                  e.instanceId!=''
                )
              })){
                ElMessage({
                  type: 'error',
                  message: '请选择微信小程序不能为空',
                })
                return
              }
            }
            //给微信小程序赋值
            if(
              state.landing_type == 'MICRO_GAME' &&
              state.microPromotionType == 'WECHAT_APP'             
            ){
              state.wechatAppDtos = state.SonAccountDataNew5
            }
            // 判断字节小程序
            if (
              state.landing_type == 'MICRO_GAME' &&
              state.microPromotionType == 'BYTE_APP'
            ) {
              if (
                !state.SonAccountDataNew4.every((e) => {
                  return (
                    e.instanceId != '' && e.assetId != '' && e.startParam != ''
                  )
                })
              ) {
                ElMessage({
                  type: 'error',
                  message: '请选择字节小程序、小程序链接、事件资产不能为空',
                })
                return
              }
            }
            //给字节小程序赋值
            if (
              state.landing_type == 'MICRO_GAME' &&
              state.microPromotionType == 'BYTE_APP'
            ) {
              state.microAppDtos = []
              state.SonAccountDataNew4.forEach((e) => {
                //给字节小程序赋值
                e.getV2MicroAppData.forEach((i) => {
                  if (e.instanceId == i.instanceId) {
                    i.advertiserId = e.advertiserId
                    i.assetId = e.assetId
                    //循环list
                    e.list.forEach((f) => {
                      if (e.startParam == f.startParam) {
                        i.appId = f.appId
                        i.startParam = f.startParam
                        i.startPage = f.startPage
                        i.linkRemark = f.linkRemark
                      }
                    })
                    // 这里写赋值============================================

                    state.microAppDtos.push(i)
                  }
                })
              })
            }
            // 定向包数据和检测start
            if (state.dPackageId == '') {
              ElMessage({
                type: 'error',
                message: '请选择定向包',
              })
              return
            }
            // 判断监测链接是否填了
            if (state.landing_type == 'LINK') {
              if (
                !state.SonAccountDataNew.every((e) => {
                  return e.value != ''
                })
              ) {
                ElMessage({
                  type: 'error',
                  message: '请填写完点击监测链接',
                })
                return
              }
            }
            //判断出价
            if (
              state.cpaBid == '' &&
              state.isVersion == 1 &&
              state.smartBidType != 'NO_BID'&&
              state.deepExternalAction != 'AD_CONVERT_TYPE_LT_ROI'
            ) {
              return ElMessage({
                type: 'error',
                message: '出价不能为空',
              })
            }

            //判断ROI系数
            if (
              state.deepExternalAction != '' &&
              state.roiGoal == '' &&
              state.isVersion == 1 &&
              state.smartBidType != 'NO_BID'
            ) {
              return ElMessage({
                type: 'error',
                message: 'ROI系数不能为空',
              })
            }

            if (state.checkList.length == 0) {
              ElMessage({
                type: 'error',
                message: '请选择广告位置',
              })
            }
            // if (
            //   (state.productSetting == 'SINGLE' && state.platformId == '') ||
            //   (state.productSetting == 'SINGLE' && state.productId == '')
            // ) {
            //   ElMessage({
            //     type: 'error',
            //     message: '请选择商品',
            //   })
            // }
            //当微销售线索和自动投放时 投放位置必须是 通投智选
            if(state.isVersion==1 && state.landingType=='LINK'){
              state.inventoryCatalog='UNIVERSAL_SMART'
            }


            //根据项目判断舒服要出价
            state.bidState=true
            //-------选择了自动化
            if(state.smartBidType=='NO_BID'){
              state.bidState=false
            }  
            //-------选择了自动化
            if(state.isVersion==1&&(state.smartBidType=='NO_BID'||state.smartBidType=='CUSTOM')){
              state.bidState=false
            }
            // ------选择了iaa
            if(state.landing_type=='MICRO_GAME'&&state.microPromotionType=='BYTE_APP'&&state.isIaaProject==1&&state.deepExternalAction=='AD_CONVERT_TYPE_LT_ROI'){
              console.log('进来了111')
              state.bidState=false
            }
            const campaignDto = {
              budgetMode: state.budgetMode,
              inventoryCatalog: state.inventoryCatalog,
              projectName: state.ruleForm.projectName,
              assetType: 'ORANGE',
              scheduleType: state.scheduleType,
              landingType: state.landing_type,
              deepExternalAction: state.deepExternalAction,
              microPromotionType: state.microPromotionType,
              externalAction: state.getV2optimizedGoal,
              marketingGoal: state.marketingGoal,
              adType: state.adType,
              operation: state.operation,
              modelName: state.ruleForm.modelName,
              actionTrackUrlList: state.SonAccountDataNew,
              dpackageId: state.dPackageId,
              smartBidType: state.smartBidType,
              inventoryType: state.checkList,
              productSetting: state.productSetting,
              budgetOptimizeSwitch: state.budgetOptimizeSwitch,
              goodsMap: state.SonAccountDataNew3,
              microAppDtos: state.microAppDtos,
              wechatAppDtos: state.wechatAppDtos,
              cpaBid: state.cpaBid,
              isVersion: state.isVersion,
              deliveryMode: state.deliveryMode,
              roiGoal: state.roiGoal,
              isIaaProject:state.isIaaProject,
              bidState:state.bidState

            }

            if (state.scheduleType == 'SCHEDULE_START_END') {
              campaignDto.startTime = state.value1[0]
              campaignDto.endTime = state.value1[1]
            }
            if (state.launchTime != 0) {
              campaignDto.times = state.weektimeData
            }
            if (
              state.budgetMode == 'BUDGET_MODE_DAY' ||
              (state.smartBidType == 'NO_BID' &&
                state.budgetOptimizeSwitch == 'ON') ||
              state.isVersion == 1
            ) {
              campaignDto.budget = state.budget
              
            }
            console.log("🚀 ~ awaitformEl.validate ~项目 campaignDto:", campaignDto)
            parent.emit('campaignDto', campaignDto)
          } else {
            console.log('error submit!', fields)
          }
        })
      },
    })
    // 查询深度优化目标

    getV2ShenDuoptimizedGoal().then((res) => {
      console.log(res)
      state.getV2ShenDuoptimizedGoalData = res.data
    })
    return {
      ...toRefs(state),
      mult_timeRange,
    }
  },
}
</script>
<style lang="scss" scoped>
.toutiaoAdsSetDraweritem {
  padding-bottom: 40px;
}
.formbtn {
  text-align: right;
  margin-right: 50px;
  position: fixed;
  bottom: 0;
  right: 50px;
}

.firstMedia {
  width: 70%;
  margin-left: 120px;
  // cmargin-bottom: 30px;
  padding-bottom: 30px;
}
.r_desc {
  font-size: 12px;
  margin-top: 6px;
}

.cybox {
  display: inline-block;
  width: 520px;
  height: 200px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  overflow: auto;
  margin-top: 10px;
}
</style>
